import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import geoLocatorHandler from '@/use/useGeoLocator'

const geoLocatorsbulk = ref([])

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()
  const useApi = apiHandler()
  const useGeolocator = geoLocatorHandler()

  const fields = ref([
    {
      label: 'Address ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'address',
      key: 'address',
      type: 'string',
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
    },
    {
      label: 'place',
      key: 'place',
      type: 'string',
    },
    {
      label: 'dateCreated',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
    },
  ])

  // list
  function getRecords() {
    // Start loading state
    loadHandler.setLoadingState('get_records', true)

    // Return a new promise
    return new Promise((resolve, reject) => {
      const requestFields = ['object_id', 'address', 'zip', 'place', 'date_created', 'x', 'y']
      const fieldsParam = '?fields=' + requestFields.join(',')

      useApi
        .requestV2('get', `v1/addresses${fieldsParam}`)
        .then((result) => {
          const data = result.data
          records.value = data

          // Stop loading state
          loadHandler.setLoadingState('get_records', false)

          // Resolve the promise with data
          resolve(data)
        })
        .catch((error) => {
          // Stop loading state
          loadHandler.setLoadingState('get_records', false)

          // Reject the promise if there's an error
          reject(error)
        })
    })
  }

  // add
  function addRecord(data) {
    const payload = {
      addresses: [
        {
          address: `${data.data.straatnaam} ${data.data.huis_nlt}`,
          zip: data.data.postcode,
          place: data.data.woonplaatsnaam,
          config: data.data,
          x: data.geometry[0],
          y: data.geometry[1],
        },
      ],
    }

    loadHandler.setLoadingState('add_record', true)
    useApi.requestV2('post', `v1/addresses`, payload).then(() => {
      loadHandler.setLoadingState('add_record', false)
      showAdd.value = false
      getRecords()
    })
  }

  async function addRecordsMultiple() {
    loadHandler.setLoadingState('add_record', true)
    const addressIds = []

    geoLocatorsbulk.value.forEach((geoLocator) => {
      const addressId = geoLocator.searchResult[0]?.id || null
      if (addressId) {
        addressIds.push(addressId)
      }
    })

    const payload = {
      addresses: [],
    }

    const lookupPromises = addressIds.map(async (addressId) => {
      const result = await useGeolocator.lookup(addressId)
      const data = result.response.docs[0]
      const centroide_ll = data.centroide_ll
      const coords = centroide_ll.split('(')[1].split(')')[0].split(' ')
      const lat = Number(coords[0])
      const lng = Number(coords[1])

      const addressDetails = {
        address: `${data.straatnaam} ${data.huis_nlt}`,
        zip: data.postcode,
        place: data.woonplaatsnaam,
        config: data,
        x: lat,
        y: lng,
      }

      payload.addresses.push(addressDetails)
    })

    await Promise.all(lookupPromises)

    useApi.requestV2('post', `v1/addresses`, payload).then(() => {
      loadHandler.setLoadingState('add_record', false)
      showAdd.value = false
      getRecords()
    })
  }

  function openAdd(prefill) {
    const formOptions = { type: 'add', fields: fields.value, data: {} }

    if (prefill) {
      formOptions.data = prefill
    }

    addForm.create(formOptions)
    showAdd.value = true
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function deleteRecord(value) {
    loadHandler.setLoadingState('delete_record', true)
    useApi
      .requestV2('delete', `v1/addresses/${value}`)
      .then(() => {
        loadHandler.setLoadingState('delete_record', false)
        getRecords()
      })
      .catch((error) => {
        loadHandler.setLoadingState('delete_record', false)
        console.log(error)
      })
  }

  // exposed
  return {
    fields: fields,
    records: records,
    getRecords,
    loadHandler,
    deleteRecord,
    showAdd,
    showEdit,
    addForm,
    addRecord,
    addRecordsMultiple,
    editForm,
    closeEdit,
    openAdd,
    closeAdd,
    geoLocatorsbulk,
  }
}
