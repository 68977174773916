<template>
  <div class="h-full min-h-0">
    <div class="flex h-full flex-col overflow-y-auto">
      <div class="flex flex-row justify-between">
        <basePageTitle :title="useContentGuard.organisation.value" subtitle="Projecten" />
      </div>

      <div class="flex-grow py-10">
        <baseTable
          :fields="useProject.fields.value"
          :records="useProject.records.value"
          :records-per-page="25"
          :add-button="true"
          :delete-button="true"
          :clickAble="true"
          :clickCallback="tableActionProject"
          :loading="useProject.loadHandler.somethingLoading.value"
          @add="openAddProject"
          @edit="useProject.openEdit"
          @delete="useProject.deleteRecord"
        />
      </div>

      <baseModal
        v-if="useProject.showAdd.value"
        @close="useProject.closeAdd()"
        :title="$translate('Add')"
        @enter="useProject.onEnter()"
      >
        <baseForm :handler="useProject.addForm" :loading="useProject.loadHandler.states['add_record']" />
        <template #buttons>
          <baseButton @action="useProject.addRecord()" :loading="useProject.loadHandler.states['add_record']">
            {{ $translate('Add') }}
          </baseButton>
        </template>
      </baseModal>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import contentGuardHandler from '@/use/contentGuardHandler'
import projectHandler from '@/use/projectHandler'

const router = useRouter()
const useContentGuard = contentGuardHandler()
const useProject = projectHandler()

function tableActionProject(payload) {
  router.push({
    name: 'project',
    params: { objectId: payload['object_id'] },
  })
}

function openAddProject() {
  useProject.openAdd()
}

onMounted(() => {
  useProject.getRecords()
})
</script>
