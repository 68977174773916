<template>
  <div class="h-full min-h-0 overflow-y-auto">
    <div class="flex flex-col h-full">
      <div class="flex justify-between items-center">
        <basePageTitle :title="useContentGuard.organisation.value" subtitle="Organisatie" />
      </div>
      <div class="flex-grow pr-1 pb-2 mt-4">
        <baseTable
          :fields="fields"
          :records="users"
          :records-per-page="10"
          :sortable="true"
          :addButton="false"
          :deleteButton="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import apiHandler from '@/use/apiHandler'

const useContentGuard = contentGuardHandler()
const useApi = apiHandler()

const fields = [
  { key: 'name', label: 'Naam', sortable: true },
  { key: 'email', label: 'E-mail', sortable: true },
]

const users = ref([])

onMounted(async () => {
  try {
    const { data } = await useApi.requestV2('get', 'v1/organizations/users')
    users.value = data
  } catch (error) {
    console.error('Error fetching users:', error)
  }
})
</script>
