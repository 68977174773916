import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'
import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const maatregelCategorieForm = formHandler()
  const maatregelCategorieEditForm = formHandler()
  const project = ref({})
  const showAddAddresses = ref(false)
  const unassignedAddresses = ref([])
  const selectedUnassignedAddresses = ref([])
  const maatregelenCategorieen = ref([])
  const steps = ref([])
  const showAddmaatregelCategorie = ref(false)
  const showEditmaatregelCategorie = ref(false)
  const addStepForm = formHandler()
  const showAddStep = ref(false)
  const editIndex = ref(null)
  const tasks = ref([])
  const addTaskForm = formHandler()
  const showAddTask = ref(false)
  const taskSteps = ref([])

  const detailFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
    },
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
  ])

  const addressFields = ref([
    {
      label: 'Address ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'address',
      key: 'address',
      type: 'string',
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
    },
    {
      label: 'place',
      key: 'place',
      type: 'string',
    },
  ])

  const stepFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Type',
      key: 'type',
      type: 'select',
      options: selectValues.stapTypen,
      default: selectValues.stapTypen[0].value,
      disabled: true,
    },
    {
      label: 'Description',
      key: 'description',
      type: 'textarea',
    },
  ])

  const taskFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'textarea',
    },
    {
      label: 'Step',
      key: 'step_id',
      type: 'select',
      options: taskSteps,
    },
  ])

  const maatregelencategorieenFields = ref([
    {
      label: 'Omschrijving',
      key: 'omschrijving',
      type: 'string',
      edit: true,
    },
  ])

  async function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    try {
      const object_id = payload['object_id']

      // get project and addresses
      const response = await useApi.requestV2('get', `v1/projects/${object_id}`)
      const responseAddresses = await useApi.requestV2('get', `v1/projects/${object_id}/addresses`)

      // merge
      const response_data = response.data
      response_data['addresses'] = responseAddresses.data

      project.value = response_data
      const config = response_data.config

      if ('maatregelen_categorieen' in config) {
        maatregelenCategorieen.value = config['maatregelen_categorieen']
      }

      if ('stappen' in config) {
        steps.value = config['stappen']

        taskSteps.value = steps.value.map((step) => {
          return { label: step.name, value: step.step_id }
        })
      }
      return project.value
    } finally {
      loadHandler.setLoadingState('get_details', false)
    }
  }

  async function getUnassignedAddresses(payload) {
    loadHandler.setLoadingState('get_unassigned', true)
    try {
      const response = await useApi.requestV2('get', 'v1/addresses/projects/' + payload.object_id + '/unassigned')

      unassignedAddresses.value = response.data

      return unassignedAddresses.value
    } finally {
      loadHandler.setLoadingState('get_unassigned', false)
    }
  }

  async function assignAddresses() {
    loadHandler.setLoadingState('assign_addresses', true)
    const payload = {
      object_id: project.value.object_id,
      address_ids: selectedUnassignedAddresses.value.map((address) => address.object_id),
    }
    try {
      await useApi.request('post', 'addresses', 'assign', payload)
      closeAddAddresses()
      const getDetailsPayload = {
        object_id: project.value.object_id,
      }
      getDetails(getDetailsPayload)
    } finally {
      loadHandler.setLoadingState('assign_addresses', false)
    }
  }

  async function unassignAddress(address_id) {
    loadHandler.setLoadingState('unassign_addresses', true)
    const payload = {
      object_id: project.value.object_id,
      address_ids: [address_id],
    }
    try {
      await useApi.request('post', 'addresses', 'unassign', payload)
      const getDetailsPayload = {
        object_id: project.value.object_id,
      }
      getDetails(getDetailsPayload)
    } finally {
      loadHandler.setLoadingState('unassign_addresses', false)
    }
  }

  function openAddAddresses() {
    showAddAddresses.value = true
    const payload = {
      object_id: project.value.object_id,
    }
    getUnassignedAddresses(payload)
  }

  function closeAddAddresses() {
    showAddAddresses.value = false
    unassignedAddresses.value = []
    selectedUnassignedAddresses.value = []
  }

  function setSelectedUnassignedAddresses(payload) {
    selectedUnassignedAddresses.value = payload
  }

  function openAddMaatregelCategorie() {
    const formOptions = {
      type: 'add',
      fields: maatregelencategorieenFields.value,
      data: {},
    }
    maatregelCategorieForm.create(formOptions)
    showAddmaatregelCategorie.value = true
  }

  function openEditMaatregelCategorie(record, index) {
    editIndex.value = index
    const formOptions = {
      type: 'edit',
      fields: maatregelencategorieenFields.value,
      data: record,
    }
    maatregelCategorieEditForm.create(formOptions)
    showEditmaatregelCategorie.value = true
  }

  function closeAddMaatregelCategorie() {
    showAddmaatregelCategorie.value = false
  }

  function closeEditMaatregelCategorie() {
    editIndex.value = null
    showEditmaatregelCategorie.value = false
  }

  function AddMaatregelCategorieOnEnter() {
    // pass
  }

  function EditMaatregelCategorieOnEnter() {
    // pass
  }

  function addMaatregelCategorie() {
    loadHandler.setLoadingState('add_maatregel_categorie', true)

    const newItem = maatregelCategorieForm.data.value
    const updatedConfig = [...maatregelenCategorieen.value, newItem]

    // update project config
    project.value.config['maatregelen_categorieen'] = updatedConfig

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        maatregelenCategorieen.value.push(newItem)
        showAddmaatregelCategorie.value = false
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('add_maatregel_categorie', false)
      })
  }

  function deleteMaatregelCategorie(_, index) {
    loadHandler.setLoadingState('delete_maatregel_categorie', true)
    const maatregelenCategorieencopy = JSON.parse(JSON.stringify(maatregelenCategorieen.value))
    const updatedConfig = maatregelenCategorieencopy.splice(index, 1)
    project.value.config['maatregelen_categorieen'] = updatedConfig

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        maatregelenCategorieen.value.splice(index, 1)
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('delete_maatregel_categorie', false)
      })
  }

  function addStep() {
    loadHandler.setLoadingState('add_step', true)

    const newItem = addStepForm.data.value
    const step_id = uuidv4()
    newItem['step_id'] = step_id

    const updatedConfig = [...steps.value, newItem]

    // update project config
    project.value.config['stappen'] = updatedConfig

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        steps.value.push(newItem)
        showAddmaatregelCategorie.value = false
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('add_step', false)
        closeAddStep()
      })
  }

  function openAddStep() {
    const formOptions = { type: 'add', fields: stepFields.value, data: {} }
    addStepForm.create(formOptions)
    showAddStep.value = true
  }

  function closeAddStep() {
    showAddStep.value = false
  }

  function deleteStep(index) {
    loadHandler.setLoadingState('delete_step', true)
    steps.value.splice(index, 1)

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        // pass
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('delete_step', false)
      })
  }

  function addTask() {
    loadHandler.setLoadingState('add_task', true)

    const newItem = addTaskForm.data.value
    const task_id = uuidv4()
    newItem['task_id'] = task_id

    // const updatedConfig = [...tasks.value, newItem]

    // update project config
    // project.value.config['stappen'] = updatedConfig

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        tasks.value.push(newItem)
        showAddmaatregelCategorie.value = false
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('add_task', false)
        closeAddStep()
      })
  }

  function openAddTask() {
    const formOptions = { type: 'add', fields: taskFields.value, data: {} }
    addTaskForm.create(formOptions)
    showAddTask.value = true
  }

  function closeAddTask() {
    showAddTask.value = false
  }

  function deleteTask(index) {
    loadHandler.setLoadingState('delete_step', true)
    tasks.value.splice(index, 1)

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        // pass
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('delete_step', false)
      })
  }

  function editMaatregelCategorie() {
    loadHandler.setLoadingState('edit_maatregel_categorie', true)
    const index = editIndex.value
    const data = maatregelCategorieEditForm.data.value
    const maatregelenCategorieencopy = JSON.parse(JSON.stringify(maatregelenCategorieen.value))
    maatregelenCategorieencopy[index] = data
    project.value.config['maatregelen_categorieen'] = maatregelenCategorieencopy

    const payload = {
      object_id: project.value.object_id,
      config: project.value.config,
    }

    useApi
      .request('post', 'projects', 'update', payload)
      .then(() => {
        maatregelenCategorieen.value = maatregelenCategorieencopy
        closeEditMaatregelCategorie()
      })
      .catch((error) => {
        console.error('Update failed', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('edit_maatregel_categorie', false)
      })
  }

  return {
    loadHandler,
    getDetails,
    project,
    detailFields,
    addressFields,
    openAddAddresses,
    closeAddAddresses,
    assignAddresses,
    showAddAddresses,
    unassignedAddresses,
    setSelectedUnassignedAddresses,
    unassignAddress,
    maatregelenCategorieen,
    maatregelencategorieenFields,
    showAddmaatregelCategorie,
    closeAddMaatregelCategorie,
    AddMaatregelCategorieOnEnter,
    addMaatregelCategorie,
    openAddMaatregelCategorie,
    openEditMaatregelCategorie,
    maatregelCategorieForm,
    stepFields,
    addStepForm,
    showAddStep,
    openAddStep,
    closeAddStep,
    addStep,
    steps,
    deleteStep,
    deleteMaatregelCategorie,
    maatregelCategorieEditForm,
    showEditmaatregelCategorie,
    closeEditMaatregelCategorie,
    EditMaatregelCategorieOnEnter,
    editMaatregelCategorie,
    addTask,
    tasks,
    addTaskForm,
    showAddTask,
    openAddTask,
    closeAddTask,
    deleteTask,
  }
}
