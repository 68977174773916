<template>
  <div class="h-full min-h-0 overflow-hidden">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto overflow-x-hidden">
        <!-- title -->
        <div>
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <basePageTitle :title="useContentGuard.organisation.value" subtitle="Adressen" />
            </div>
          </div>
        </div>

        <!-- content container -->
        <div class="flex flex-row min-h-0 w-full">
          <div class="py-10 w-full">
            <!-- tabs -->
            <!-- adrestab -->
            <div class="flex flex-row gap-4">
              <div class="basis-1/2">
                <!-- adrestable -->
                <baseTable
                  :fields="useAddress.fields.value"
                  :records="useAddress.records.value"
                  :records-per-page="25"
                  :add-button="true"
                  :edit-button="false"
                  :delete-button="true"
                  :duplicate-button="false"
                  :export-record-button="false"
                  :clickAble="true"
                  :clickCallback="tableAction"
                  :loading="useAddress.loadHandler.somethingLoading.value"
                  @add="openAdd"
                  @edit="useAddress.openEdit"
                  @delete="useAddress.deleteRecord"
                />

                <!-- add adres modal -->
                <baseModal
                  v-if="useAddress.showAdd.value"
                  @close="useAddress.closeAdd()"
                  :title="$translate('Add')"
                  @enter="useAddress.onEnter()"
                  size="l"
                >
                  <baseTabs :handler="addressTabs" @changed="onTabChangeAddress">
                    <template v-slot:panel-single>
                      <base-geolocator
                        :handler="geoLocatorHandler"
                        class="w-full mb-2"
                        @lookupresult="geolocatorAction"
                      />
                      <div class="w-full h-96 z-0" id="map_pop"></div>
                    </template>
                    <template v-slot:panel-bulk>
                      <addBulkAddresses :handler="useAddress"></addBulkAddresses>
                    </template>
                  </baseTabs>

                  <template v-slot:buttons>
                    <baseButton @action="addRecord()" :loading="useAddress.loadHandler.states[`add_record`]">
                      {{ $translate('Add') }}
                    </baseButton>
                  </template>
                </baseModal>
              </div>
              <div class="basis-1/2">
                <div class="w-full h-[48em] z-0 rounded shadow-md bg-white" id="map_overview"></div>
                <!-- <baseMap
                  class="w-full h-[48em] z-0 rounded shadow-md bg-white"
                  id="default-map"
                  :handler="testMapHandler"
                ></baseMap> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, nextTick, ref, computed, watch } from 'vue'
import addressHandler from '@/use/addressHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import nodeMapHandler from '@/use/nodeMapHandler'
import nodeMapHandlerMultiple from '@/use/nodeMapHandlerMultiple'
import useGeoLocator from '@/use/useGeoLocator'
import router from '@/router'
import tabHandler from '@/use/tabHandler'
import projectHandler from '@/use/projectHandler'
import addBulkAddresses from './components/addBulkAddresses.vue'
import { useMap } from '@/use/map/mapHandler'

export default {
  setup() {
    const useContentGuard = contentGuardHandler()
    // const useToast = toastHandler()
    const tabs = tabHandler()
    const addressTabs = tabHandler()
    const addressMap = ref(null)
    const adresMarker = ref(null)
    const addressData = ref({})

    const overviewMap = ref(null)
    const overviewMapMarkers = ref(null)

    const checked = ref(false)

    const testMapHandler = useMap()

    // handlers
    const useAddress = addressHandler()
    const useProject = projectHandler()
    const useNodeMap = nodeMapHandler()
    const useNodeMapMultiple = nodeMapHandlerMultiple()
    const geoLocatorHandler = useGeoLocator()

    function tableAction(payload) {
      router.push({
        name: 'address',
        params: { objectId: payload['object_id'] },
      })
    }

    function tableActionProject(payload) {
      router.push({
        name: 'project',
        params: { objectId: payload['object_id'] },
      })
    }

    function openAdd() {
      useAddress.openAdd()

      nextTick(() => {
        const settings = {
          lat: 52,
          lng: 6,
          zoom: 6,
          markerType: null,
          nodeId: null,
          geometry: false,
        }
        const { map, marker } = useNodeMap.renderMap('map_pop', 'map', false, settings)
        addressMap.value = map
        adresMarker.value = marker
      })
    }

    function openAddProject() {
      useProject.openAdd()
    }

    function geolocatorAction(result) {
      addressData.value = result
      useNodeMap.panMap(addressMap.value, result.geometry[1], result.geometry[0], 19)
      useNodeMap.addSearchMarker(addressMap.value, result.geometry[1], result.geometry[0])
    }

    function addRecord() {
      if (useAddress.geoLocatorsbulk.value.length > 0) {
        useAddress.addRecordsMultiple()
      } else {
        useAddress.addRecord(addressData.value)
      }
    }

    const tabOptions = computed(() => {
      if (useContentGuard.permissions.value.includes('organisation:proces') ? true : false) {
        return {
          tabs: [
            { id: 'adressen', label: 'Adressen', active: true },
            { id: 'projecten', label: 'Projecten', active: true },
          ],
        }
      }
      return {
        tabs: [{ id: 'opnames', label: 'Opnames', active: true }],
      }
    })

    const tabOptionsAddress = computed(() => {
      return {
        tabs: [
          { id: 'single', label: 'Enkel', active: true },
          { id: 'bulk', label: 'Bulk', active: true },
        ],
      }
    })

    const recordsLoaded = ref(false)
    const mapInitialized = ref(false)
    const mapInitializedAddAddress = ref(false)
    const currentTab = ref(null)
    const currentTabAddress = ref(null)

    onMounted(() => {
      useAddress.getRecords().then(() => {
        recordsLoaded.value = true
      })
      useProject.getRecords()
      tabs.create(tabOptions.value)
      addressTabs.create(tabOptionsAddress.value)
    })

    // Reactively watch for the right conditions to initialize the overview map
    watch(
      [recordsLoaded],
      ([newRecordsLoaded]) => {
        nextTick(() => {
          if (newRecordsLoaded && !mapInitialized.value) {
            const settings = {
              markerType: 'pinMarker',
              nodes: useAddress.records.value,
            }
            const { map, marker } = useNodeMapMultiple.renderMap('map_overview', 'minimap', false, settings)
            // testMapHandler.updateLayerData('default-points', useAddress.records.value).then(() => {
            //   testMapHandler.zoomToFit()
            // })
            overviewMap.value = map
            overviewMapMarkers.value = marker
            mapInitialized.value = true
          }
        })
      },
      {
        immediate: true,
      }
    )

    // Reactively watch for the right conditions to initialize the add address map
    watch(
      [() => useAddress.showAdd.value, currentTabAddress],
      ([showAdd, newCurrentTabAddress]) => {
        nextTick(() => {
          if (showAdd && newCurrentTabAddress === 'single' && !mapInitializedAddAddress.value) {
            const settings = {
              lat: 52,
              lng: 6,
              zoom: 6,
              markerType: null,
              nodeId: null,
              geometry: false,
            }
            const { map, marker } = useNodeMap.renderMap('map_pop', 'map', false, settings)
            addressMap.value = map
            adresMarker.value = marker
            mapInitializedAddAddress.value = true
          }
        })
      },
      {
        immediate: true,
      }
    )

    async function onTabChange(changeInfo) {
      const { source, target } = changeInfo
      currentTab.value = target

      if (source === 'adressen') {
        mapInitialized.value = false
      }
    }

    async function onTabChangeAddress(changeInfo) {
      const { source, target } = changeInfo
      currentTabAddress.value = target

      if (source === 'single') {
        mapInitializedAddAddress.value = false
      }
    }

    return {
      tableAction,
      useAddress,
      useContentGuard,
      useNodeMap,
      geoLocatorHandler,
      geolocatorAction,
      openAdd,
      addRecord,
      tabs,
      addressTabs,
      onTabChange,
      useProject,
      openAddProject,
      tableActionProject,
      onTabChangeAddress,
      currentTabAddress,
      checked,
      testMapHandler,
    }
  },
  components: {
    addBulkAddresses,
  },
}
</script>
