<template>
  <div class="flex h-screen overflow-hidden">
    <!-- sidebar -->
    <div
      :class="`fixed h-screen overflow-hidden transition-all duration-300 ease-in-out ${surfaceColour} drop-shadow-xl ${
        sidebar.show.value ? 'w-52' : 'w-14'
      }`"
    >
      <sidebar :collapsed="!sidebar.show.value" @toggle="sidebar.toggle" />
    </div>
    <!-- content -->
    <div
      :class="`flex-grow ${backgroundColor} overflow-y-auto transition-all duration-300 ease-in-out ${
        sidebar.show.value ? 'ml-52' : 'ml-14'
      }`"
    >
      <div class="h-full">
        <div class="px-10 pt-5">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useTheme from '@/use/themeHandler'
import sidebar from './sidebar/sideBar.vue'
import useSidebar from '@/use/sidebarHandler'

export default {
  setup() {
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')
    const backgroundColor = themeHandler.getBackgroundColor('background')
    const sidebar = useSidebar()
    return {
      backgroundColor,
      surfaceColour,
      sidebar,
    }
  },
  components: {
    sidebar,
  },
}
</script>
