<template>
  <div class="flex flex-col h-full p-4 relative">
    <div class="flex flex-row text-gray-400">
      <div class="cursor-pointer" @click="toggle">
        <featherIcon class="w-6 h-6" icon="SidebarIcon" />
      </div>
    </div>

    <div>
      <logoSideBar
        class="w-full h-full transition-opacity duration-300 p-4"
        :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
      />
    </div>

    <div>
      <ul>
        <li v-for="item in items" :key="item.label || item.header">
          <div
            v-if="!item.header"
            @click="goTo(item.route_name)"
            class="flex flex-row font-montserrat text-gray-500 px-1 py-2 hover:translate-x-1 ease-in-out duration-300 cursor-pointer"
          >
            <div class="mr-4 my-auto"><featherIcon class="w-5 h-5" :icon="item.icon" /></div>
            <div
              class="overflow-hidden transition-opacity duration-300"
              :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
            >
              <div class="align-middle">{{ $translate(item.label) }}</div>
            </div>
          </div>
          <div v-else class="flex flex-row text-xs font-montserrat text-gray-500 p-2 mt-4 uppercase">
            <div
              class="overflow-hidden transition-opacity duration-300"
              :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
            >
              {{ $translate(item.header) }}
            </div>
          </div>
        </li>
        <li v-if="useContentGuard.canSee('admin:read')">
          <div
            class="flex flex-row font-montserrat text-gray-500 px-1 py-2 hover:translate-x-1 ease-in-out duration-300 cursor-pointer"
            @click="clickAdmin"
          >
            <div class="mr-4 my-auto"><featherIcon class="w-5 h-5" icon="PieChartIcon" /></div>
            <div
              class="overflow-hidden transition-opacity duration-300"
              :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
            >
              <div class="align-middle">{{ $translate('Admin') }}</div>
            </div>
          </div>
        </li>
        <li>
          <div
            class="flex flex-row font-montserrat text-gray-500 px-1 py-2 hover:translate-x-1 ease-in-out duration-300 cursor-pointer"
            @click="clickManual"
          >
            <div class="mr-4 my-auto"><featherIcon class="w-5 h-5" icon="BookOpenIcon" /></div>
            <div
              class="overflow-hidden transition-opacity duration-300"
              :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
            >
              <div class="align-middle">{{ $translate('Manual') }}</div>
            </div>
          </div>
        </li>
        <li>
          <div
            class="flex flex-row font-montserrat text-gray-500 px-1 py-2 hover:translate-x-1 ease-in-out duration-300 cursor-pointer"
            @click="logOut"
          >
            <div class="mr-4 my-auto"><featherIcon class="w-5 h-5" icon="LogOutIcon" /></div>
            <div
              class="overflow-hidden transition-opacity duration-300"
              :class="{ 'opacity-100': !collapsed, 'opacity-0': collapsed }"
            >
              <div class="align-middle">{{ $translate('Log out') }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import exportHandler from '@/use/exportHandler'
import logoSideBar from '@/components/extended/logoMain.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggle'])

const useContentGuard = contentGuardHandler()
const auth = useAuth0()
const useExport = exportHandler()
const router = useRouter()

const items = computed(() => {
  if (useContentGuard.permissions.value.includes('organisation:proces')) {
    return [
      { header: 'Overzichten' },
      { label: 'Adressen', icon: 'HomeIcon', route_name: 'addresses' },
      { label: 'Projecten', icon: 'BriefcaseIcon', route_name: 'projects' },
      { header: 'Gebruiker' },
      { label: 'Taken', icon: 'CheckSquareIcon', route_name: 'tasks' },
      { label: 'Profiel', icon: 'UserIcon', route_name: 'profile' },
      { label: 'Organisatie', icon: 'UsersIcon', route_name: 'organisation' },
    ]
  } else {
    return [
      { header: 'Overzichten' },
      { label: 'Opnames', icon: 'ClipboardIcon', route_name: 'surveys' },
      { header: 'Gebruiker' },
      { label: 'Profiel', icon: 'UserIcon', route_name: 'profile' },
      { label: 'Organisatie', icon: 'UsersIcon', route_name: 'organisation' },
    ]
  }
})

function logOut() {
  auth.logout({ logoutParams: { returnTo: window.location.origin } })
}

function goTo(route_name) {
  router.push({ name: route_name })
}

function clickManual() {
  useExport.downloadManual()
}

function clickAdmin() {
  router.push({ name: 'admin' })
}

function toggle() {
  emit('toggle')
}
</script>
