import { useAuth0 } from '@auth0/auth0-vue'
import axios from 'axios'

export default () => {
  const { getAccessTokenSilently, logout } = useAuth0()

  // request interceptor
  axios.interceptors.request.use(async (config) => {
    // use auth0 function to get token
    const accessToken = await getAccessTokenSilently()
    // const accessToken = false

    // add to header
    if (accessToken) {
      // voor de toekomst: config.headers['Authorization'] = `Bearer ${accessToken}`
      config.headers.common = { Authorization: `Bearer ${accessToken}` }
    }

    return config
  })

  // response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        console.error('Access token not found or unauthorized')
        // Logout and remove cookies
        logout({ logoutParams: { returnTo: window.location.origin } })
      }

      return Promise.reject(error)
    }
  )

  const baseUrl = process.env.VUE_APP_API_BASEURL
  const baseUrlV2 = process.env.VUE_APP_API_V2_BASEURL

  // list
  async function list(object) {
    const response = await axios.get(`${baseUrl}/${object}/list`)
    const responseData = await response
    const data = responseData.data.data
    return data
  }

  // details
  async function details(object, payload) {
    const response = await axios.post(`${baseUrl}/${object}/get_details`, payload)
    const responseData = await response
    const data = responseData.data.data
    return data
  }

  // add
  async function add(object, payload) {
    const response = await axios.post(`${baseUrl}/${object}/add`, payload)
    const responseData = await response
    // const message = responseData.data.message
    return responseData
    // todo define succes, return message or trigger message handler
  }

  async function edit(object, payload) {
    const response = await axios.post(`${baseUrl}/${object}/update`, payload)
    const responseData = await response
    // const message = responseData.data.message
    return responseData
    // todo define succes, return message or trigger message handler
  }

  async function remove(object, payload) {
    const response = await axios.post(`${baseUrl}/${object}/delete`, payload)
    const responseData = await response
    // const message = responseData.data.message
    return responseData
    // todo define succes, return message or trigger message handler
  }

  async function generate(object, payload) {
    const response = await axios.post(`${baseUrl}/${object}/generate_report`, payload, { responseType: 'arraybuffer' })
    const responseData = await response
    // const message = responseData.data.message
    return responseData
    // todo define succes, return message or trigger message handler
  }

  async function download(object) {
    const response = await axios.get(`${baseUrl}/${object}/download`, { responseType: 'blob' })
    const responseData = await response
    return responseData
  }

  async function download_attachment(attachment_id) {
    const response = await axios.get(`${baseUrl}/attachments/download/${attachment_id}`, { responseType: 'blob' })
    const responseData = await response
    return responseData
  }

  async function list_admin(object) {
    const response = await axios.get(`${baseUrl}/${object}/list_admin`)
    const responseData = await response
    const data = responseData.data.data
    return data
  }

  async function request(type, object, endpoint, payload, headers) {
    if (type === 'post') {
      // gateway check if payload
      if (!payload) {
        console.error(`A Post request to ${endpoint} needs a payload!`)
        return null
      }

      // do the request
      const response = await axios.post(`${baseUrl}/${object}/${endpoint}`, payload, {
        headers: headers,
      })
      const responseData = await response
      return responseData
    } else {
      // do the request
      const response = await axios.get(`${baseUrl}/${object}/${endpoint}`)
      const responseData = await response
      return responseData
    }
  }

  async function requestV2(type, endpoint, payload, headers) {
    if (type === 'get') {
      const response = await axios.get(`${baseUrlV2}${endpoint}`, {
        headers: headers,
      })
      return response
    } else if (type === 'post') {
      if (!payload) {
        console.error(`A Post request to ${endpoint} needs a payload!`)
        return null
      }
      const response = await axios.post(`${baseUrlV2}${endpoint}`, payload, {
        headers: headers,
      })
      return response
    } else if (type === 'delete') {
      const response = await axios.delete(`${baseUrlV2}${endpoint}`, {
        headers: headers,
      })
      return response
    } else if (type === 'put') {
      const response = await axios.put(`${baseUrlV2}${endpoint}`, payload, {
        headers: headers,
      })
      return response
    } else if (type === 'patch') {
      const response = await axios.patch(`${baseUrlV2}${endpoint}`, payload, {
        headers: headers,
      })
      return response
    }
  }

  function arrayToCommaSeparatedString(array) {
    if (!Array.isArray(array)) {
      throw new Error('Input must be an array')
    }
    return array.join(',')
  }

  // exposed
  return {
    list,
    add,
    edit,
    remove,
    details,
    generate,
    download,
    list_admin,
    request,
    download_attachment,
    requestV2,
    arrayToCommaSeparatedString,
  }
}
