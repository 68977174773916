/* eslint-disable no-unused-vars */
import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import ExcelJS from 'exceljs'

export default () => {
  let surveys = ref([])
  const useApi = apiHandler()
  const loadHandler = loadingHandler()

  function getProjectSurveys(projectId, keys, project) {
    loadHandler.setLoadingState('get_records', true)
    const payload = { project: projectId }
    useApi.request('post', 'surveys', 'get_by', payload).then((result) => {
      surveys.value = result.data.data
      exportSurveysToExcel(surveys, keys, project)
      loadHandler.setLoadingState('get_records', false)
    })
  }

  function getUnitsForCategory(category) {
    const units = new Set()
    surveys.value.forEach((survey) => {
      // Ensure survey.config.maatregelen is an array before iterating
      ;(survey.config.maatregelen || []).forEach((maatregel) => {
        // Check if maatregel is not null/undefined and maatregel.categorie matches the category
        if (maatregel && maatregel.categorie === category) {
          units.add(maatregel.eenheid)
        }
      })
    })
    return [...units]
  }

  function summarizemaatregelen(data, uniqueMeasures) {
    // Create a summary object to hold the totals for each category and unit
    const summary = {}

    // Loop through each item in the data array
    data.forEach((item) => {
      // Check if the category of the item is within the unique measures list
      if (uniqueMeasures.includes(item.categorie)) {
        // Generate a unique key from category and unit
        const key = `${item.categorie}-${item.eenheid}`

        // If the key doesn't exist in the summary, initialize it
        if (!summary[key]) {
          summary[key] = {
            categorie: item.categorie,
            eenheid: item.eenheid,
            totaalHoeveelheid: 0,
            totaalKosten: 0,
            totaalSubsidie: 0,
          }
        }

        // Aggregate the values
        summary[key].totaalHoeveelheid += parseFloat(item.hoeveelheid) || 0
        summary[key].totaalKosten += parseFloat(item.kosten) || 0
        summary[key].totaalSubsidie += parseFloat(item.subsidie) || 0
      }
    })

    // Convert the summary object back to an array of objects
    return Object.values(summary)
  }

  async function exportSurveysToExcel(surveys, checkedPaths, project) {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Surveys')

    const paths = checkedPaths.paths
    const labels = checkedPaths.labels
    const functions = checkedPaths.functions

    let maatregelenOmschrijvingen = []

    if (project.value.config.maatregelen_categorieen) {
      maatregelenOmschrijvingen = project.value.config.maatregelen_categorieen.map((cat) => cat.omschrijving)
    }

    const normalePaths = {}
    const maatregelenPaths = {}

    Object.keys(paths).forEach((key) => {
      const payload = paths[key]
      if (maatregelenOmschrijvingen.includes(key)) {
        maatregelenPaths[key] = payload
      } else {
        normalePaths[key] = payload
      }
    })

    const headers = Object.keys(normalePaths).concat(Object.keys(maatregelenPaths))

    // Generate dynamic headers based on the categories provided in paths
    const headersParsed = headers.flatMap((header) => {
      // Check if the header is a category that requires special handling
      if (project.value.config.maatregelen_categorieen?.some((cat) => cat.omschrijving === header)) {
        // For categories, create headers for each unit found in the data
        const units = new Set(
          surveys.value.flatMap((survey) =>
            (survey.config.maatregelen || []) // Check if maatregelen is not null or undefined
              .filter((m) => m && m.categorie === header) // Check if m is not null or undefined before accessing m.categorie
              .map((m) => m.eenheid)
          )
        )

        return Array.from(units).flatMap((unit) => [
          `${header}: Hoeveelheid (${unit})`,
          `${header}: Totaal Kosten (${unit})`,
          `${header}: Totaal Subsidie (${unit})`,
        ])
      } else {
        // For regular headers, just return the header name
        return labels[header] || header
      }
    })

    // Add all headers to the worksheet
    worksheet.addRow(headersParsed)

    // Process each survey
    surveys.value.forEach((survey) => {
      const baseData = Object.keys(normalePaths).map((header) => {
        // Use the paths array to access the appropriate value
        const pathArray = paths[header]

        // const func = functions[header]

        let value = survey

        pathArray.forEach((part) => {
          value = value && value[part] ? value[part] : null
        })
        return value != null ? value.toString() : ''
      })

      // Aggregate data for all unique measures
      const measureData = summarizemaatregelen(survey.config.maatregelen, Object.keys(maatregelenPaths))

      // Transform measure data into a flat array matching dynamic headers
      let dynamicData = []
      Object.keys(maatregelenPaths).forEach((item) => {
        const units = getUnitsForCategory(item)

        units.forEach((unit) => {
          const result = measureData.find((m) => m.categorie === item && m.eenheid === unit) || {
            totaalHoeveelheid: 0,
            totaalKosten: 0,
            totaalSubsidie: 0,
          }

          dynamicData.push(result.totaalHoeveelheid)
          dynamicData.push(result.totaalKosten)
          dynamicData.push(result.totaalSubsidie)
        })
      })

      worksheet.addRow(baseData.concat(dynamicData))
    })

    // Apply styling and set column widths
    worksheet.getRow(1).font = { bold: true }
    worksheet.columns.forEach((column) => {
      let maxLength = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
        let cellLength = cell.value ? cell.value.toString().length : 10
        if (cellLength > maxLength) {
          maxLength = cellLength
        }
      })
      column.width = maxLength < 10 ? 10 : maxLength
    })

    // Trigger download (in browsers)
    await workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = 'Surveys.xlsx'
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    })
  }

  return {
    getProjectSurveys,
    exportSurveysToExcel,
    loadHandler,
  }
}
