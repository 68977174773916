<template>
  <div class="h-full min-h-0" v-if="useContentGuard.canSee('organisation:proces')">
    <div class="h-full">
      <div class="flex h-full flex-col">
        <!-- Header -->
        <div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                v-if="!useAddressDetails.loadHandler.states.get_address_details"
                :title="useAddressDetails.address.value.address"
              >
                <p class="text-slate-500 text-xs">{{ useAddressDetails.address.value.zip }}</p>
                <p class="text-slate-500 text-xs">{{ useAddressDetails.address.value.place }}</p>
              </basePageTitle>
              <base-loader
                class="text-xl text-slate-500 my-auto"
                :loading="useAddressDetails.loadHandler.states.get_address_details"
              />
            </div>
          </div>

          <!-- Address Details and Map -->
          <div class="flex flex-row mt-4">
            <div class="basis-1/2 mr-4">
              <baseCard v-if="!useAddressDetails.loadHandler.states.get_address_details" class="overflow-y-auto">
                <base-key-value
                  :data="useAddressDetails.address.value"
                  :fields="useAddressDetails.addressDetailFields.value"
                />
              </baseCard>
              <div class="flex -flex-row gap-4 mt-4">
                <base-card
                  v-if="Object.keys(useAddressDetails.label.value).length > 0"
                  :loading="useAddressDetails.loadHandler.somethingLoading.value"
                  :title="`Energielabel: ${useAddressDetails.label.value.energieklasse}`"
                  titleIcon="TagIcon"
                  :class="labelStyle(useAddressDetails.label.value.energieklasse)"
                  @click="tagClick()"
                >
                  <div class="text-sm">{{ useAddressDetails.label.value.gebouwtype }}</div>
                  <div class="text-sm">{{ useAddressDetails.label.value.registratiedatum }}</div>
                </base-card>
                <base-card
                  v-else-if="!useAddressDetails.loadHandler.states.get_address_details"
                  :loading="useAddressDetails.loadHandler.somethingLoading.value"
                  title="Energielabel: onbekend"
                  titleIcon="TagIcon"
                  class="basis-1/2 hover:bg-gray-50 cursor-pointer text-gray-500"
                  @click="tagClick()"
                >
                </base-card>
              </div>
            </div>
            <div class="basis-1/2">
              <div v-show="!useAddressDetails.loadHandler.states.get_address_details" class="h-full">
                <div class="w-full h-full z-10 rounded shadow-md bg-white ml-2" id="detail_map"></div>
              </div>
            </div>
          </div>

          <!-- Surveys and Tabs -->
          <div class="flex flex-row mt-4">
            <div v-if="!useAddressDetails.loadHandler.states.get_address_details" class="w-1/2 mr-4">
              <div class="my-8">
                <basePageTitle title="Opnames" class="my-8"></basePageTitle>
              </div>
              <baseTable
                :fields="useSurvey.fields.value"
                :records="useSurvey.records.value"
                :records-per-page="25"
                :add-button="true"
                :edit-button="true"
                :delete-button="true"
                :export-record-button="true"
                :clickAble="true"
                :clickCallback="tableAction"
                :loading="useSurvey.loadHandler.somethingLoading.value"
                @add="onOpenAdd"
                @edit="onOpenEdit"
                @delete="onDelete"
                @duplicate="useSurvey.duplicateRecord"
                @export_record="useSurvey.exportRecord"
              />
            </div>
            <div class="w-1/2 ml-4" v-if="!useAddressDetails.loadHandler.states.get_address_details">
              <baseTabs :handler="tabs">
                <template v-slot:panel-attachments>
                  <div>
                    <baseAttachments :attachable_id="route.params.objectId" attachable_type="address" :title="false" />
                  </div>
                </template>
                <template v-slot:panel-comments>
                  <div class="">
                    <baseComments :commentable_id="route.params.objectId" commentable_type="address" :title="false" />
                  </div>
                </template>
              </baseTabs>
            </div>
          </div>
          <div class="mt-4">
            <baseTable
              :fields="taskFields"
              :records="tasks"
              :records-per-page="10"
              actionField="Afgerond"
              @action="completeTask"
              actionValue="completed"
              :sortable="true"
              :add-button="true"
              :edit-button="true"
              @edit="onOpenEditTask"
              @add="onOpenAddTask"
            />
          </div>

          <!-- Modals -->
          <baseModal
            v-if="useSurvey.showAdd.value"
            @close="useSurvey.closeAdd()"
            :title="$translate('Add')"
            @enter="onEnter()"
          >
            <baseForm :handler="useSurvey.addForm" :loading="useSurvey.loadHandler.states['add_record']" />
            <template v-slot:buttons>
              <baseButton @action="useSurvey.addRecord()" :loading="useSurvey.loadHandler.states['add_record']">
                {{ $translate('Add') }}
              </baseButton>
              <baseButton
                class="mr-2"
                @action="useImport.openFilePicker('woningtool', syncWoningtool)"
                :loading="useSurvey.loadHandler.states['import_record']"
              >
                <div class="flex felx-row">
                  <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                  <span>Woningtool</span>
                </div>
              </baseButton>
              <baseButton
                v-if="useContentGuard.canSee('monitor:import')"
                class="mr-2"
                @action="useImport.openImportXMLModal()"
                :loading="useImport.loadHandler.states['import_record']"
              >
                <div class="flex felx-row">
                  <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                  <span>Monitor</span>
                </div>
              </baseButton>
            </template>
          </baseModal>

          <baseModal v-if="useSurvey.showEdit.value" @close="useSurvey.closeEdit()" :title="$translate('Edit')">
            <baseForm :handler="useSurvey.editForm" :loading="useSurvey.loadHandler.states['edit_record']" />
            <template v-slot:buttons>
              <baseButton @action="useSurvey.editRecord()" :loading="useSurvey.loadHandler.states['edit_record']">
                {{ $translate('Edit') }}
              </baseButton>
            </template>
          </baseModal>

          <baseModal v-if="showEditTaskForm" @close="showEditTaskForm = false" :title="$translate('Edit')">
            <baseForm :handler="editTaskForm" />
            <template v-slot:buttons>
              <baseButton @action="editTask()">
                {{ $translate('Edit') }}
              </baseButton>
            </template>
          </baseModal>

          <baseModal v-if="showAddTaskForm" @close="showAddTaskForm = false" :title="$translate('Add')">
            <baseForm :handler="addTaskForm" />
            <template v-slot:buttons>
              <baseButton @action="addTask(addTaskForm.data.value)">
                {{ $translate('Add') }}
              </baseButton>
            </template>
          </baseModal>

          <baseModal
            v-if="useImport.showImportXMLModal.value"
            @close="useImport.closeImportXMLModal()"
            title="Import monitorbestanden"
          >
            <baseForm :handler="useImport.importXMLForm" :loading="useImport.loadHandler.states['import_record']" />
            <template v-slot:buttons>
              <baseButton @action="onImport" :loading="useImport.loadHandler.states['import_record']">
                {{ $translate('Import') }}
              </baseButton>
            </template>
          </baseModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import addressDetailHandler from '@/use/addressDetailHandler'
import nodeMapHandler from '@/use/nodeMapHandler'
import surveyHandler from '@/use/surveyHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import commentHandler from '@/use/commentHandler'
import attachmentHandler from '@/use/attachmentHandler'
import tabHandler from '@/use/tabHandler'
import apiHandler from '@/use/apiHandler'
import importHandler from '@/use/importHandler'
import formHandler from '@/use/formHandler'

const router = useRouter()
const route = useRoute()
const useAddressDetails = addressDetailHandler()
const useNodeMap = nodeMapHandler()
const useSurvey = surveyHandler()
const useContentGuard = contentGuardHandler()
const useComments = commentHandler()
const useAttachments = attachmentHandler()
const tabs = tabHandler()
const useApi = apiHandler()
const useImport = importHandler()
const tasks = ref([])
const showEditTaskForm = ref(false)
const showAddTaskForm = ref(false)
const editTaskForm = formHandler()
const addTaskForm = formHandler()
const users = ref([])
const projects = ref([])
const steps = ref([])
const tabOptions = computed(() => {
  return {
    tabs: [
      { id: 'attachments', label: 'Bijlagen', active: true },
      { id: 'comments', label: 'Opmerkingen', active: true },
    ],
  }
})
const taskFields = [
  { key: 'deadline', label: 'Deadline', type: 'date', sortable: true },
  { key: 'name', label: 'Naam', sortable: true },
  { key: 'description', label: 'Omschrijving', sortable: true },
  { key: 'users', label: 'Gebruikers', sortable: true },
]
const editTaskFormFields = [
  { key: 'name', label: 'Name', type: 'string' },
  { key: 'description', label: 'Description', type: 'string' },
  { key: 'deadline', label: 'Deadline', type: 'date' },
  { key: 'users', label: 'Users', type: 'select', options: users },
]

const addTaskFormFields = [
  { key: 'name', label: 'Name', type: 'string' },
  { key: 'description', label: 'Description', type: 'string' },
  { key: 'deadline', label: 'Deadline', type: 'date' },
  { key: 'users', label: 'Users', type: 'select', options: users },
  { key: 'step', label: 'Step', type: 'select', options: steps },
]

editTaskForm.create({ type: 'edit', fields: editTaskFormFields, data: { deadline: 0 } })

onMounted(async () => {
  const payload = { object_id: route.params.objectId }
  await useAddressDetails.getDetails(payload)
  await loadRelatedData()
  renderMap()
  tabs.create(tabOptions.value)

  const requestFields = ['object_id', 'name']
  const fieldsParam = '?fields=' + requestFields.join(',')
  useApi.requestV2('get', `v1/projects/${fieldsParam}`).then((result) => {
    projects.value = result.data
  })
  useApi.requestV2('get', `v1/projects/${route.params.projectId}/steps`).then((result) => {
    steps.value = result.data.map((step) => ({ label: step.name, value: step.id }))
    addTaskForm.create({ type: 'add', fields: addTaskFormFields, data: { deadline: 0, step: steps.value[0].value } })
  })
})

function renderMap() {
  const geometry = useAddressDetails.address_geometry.value
  const settings = {
    lat: geometry.lat,
    lng: geometry.lng,
    zoom: 19,
    markerType: 'pinMarker',
    nodeId: null,
    geometry: geometry,
    energieKlasse: useAddressDetails.label.value?.[0]?.energieklasse ?? 'default',
  }
  useNodeMap.renderMap('detail_map', 'map_detail', false, settings)
}

async function loadRelatedData() {
  const object_id = useAddressDetails.address.value.object_id
  useSurvey.getRecords(object_id)
  useComments.list({ commentable_id: object_id, commentable_type: 'address' })
  useAttachments.list({ attachable_id: object_id, attachable_type: 'address' })
  loadTasks()
  loadUsers()
}

async function loadTasks() {
  const project_id = route.params.projectId

  if (!project_id) return

  const response = await useApi.requestV2('get', `v1/projects/${project_id}/addresses/${route.params.objectId}/tasks`)
  tasks.value = response.data
  tasks.value.forEach((task) => {
    task.users = task.users.map((user) => user.name).join(', ')
  })
}

async function loadUsers() {
  const usersResponse = await useApi.requestV2('get', 'v1/organizations/users')
  users.value = usersResponse.data.map((user) => ({ label: user.name, value: user.id }))
}

function tagClick() {
  // Implement tag click functionality
}

function labelStyle(energieklasse) {
  const styles = {
    'A++++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    'A+++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    'A++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    'A+': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    A: '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
    B: '!bg-[#79b752] basis-1/2 cursor-pointer hover:!bg-[#79b752]/75 text-white',
    C: '!bg-[#c3d545] basis-1/2 cursor-pointer hover:!bg-[#c3d545]/75 text-white',
    D: '!bg-[#fff12c] basis-1/2 cursor-pointer hover:!bg-[#fff12c]/75 text-white',
    E: '!bg-[#edb731] basis-1/2 cursor-pointer hover:!bg-[#edb731]/75 text-white',
    F: '!bg-[#d66f2c] basis-1/2 cursor-pointer hover:!bg-[#d66f2c]/75 text-white',
    G: '!bg-[#cc232a] basis-1/2 cursor-pointer hover:!bg-[#cc232a]/75 text-white',
  }
  return styles[energieklasse] || ''
}

function tableAction(payload) {
  router.push({ name: 'survey', params: { objectId: payload['object_id'] } })
}

function onOpenAdd() {
  const address_data = useAddressDetails.address.value
  const payload = {
    address: address_data.address,
    address_id: address_data.object_id,
    zip: address_data.zip,
    place: address_data.place,
  }
  if (route.params.projectId) {
    payload.project = route.params.projectId
  }
  useSurvey.openAdd(payload, address_data.object_id, projects)
}

function onOpenEdit(data) {
  const address_data = useAddressDetails.address.value
  useSurvey.openEdit(data, address_data.object_id, projects)
}

function onOpenEditTask(data) {
  showEditTaskForm.value = true
  data.users = users.value.find((user) => user.label === data.users).value
  editTaskForm.data.value = data
}

function onOpenAddTask() {
  showAddTaskForm.value = true
}

function onDelete(object_id) {
  const address_id = useAddressDetails.address.value.object_id
  useSurvey.deleteRecord(object_id, address_id)
}

async function completeTask(record, completed) {
  tasks.value = tasks.value.map((task) => (task.id === record.id ? { ...task, completed: completed } : task))
  await useApi.requestV2('patch', `v1/tasks/${record.id}`, { completed: completed })
  // await loadTasks()
}

function editTask() {
  const payload = {
    id: editTaskForm.data.value.id,
    name: editTaskForm.data.value.name,
    description: editTaskForm.data.value.description,
    deadline: editTaskForm.data.value.deadline,
    users: [editTaskForm.data.value.users],
  }
  useApi.requestV2('patch', `v1/tasks/${payload.id}`, payload).then(() => {
    showEditTaskForm.value = false
    loadTasks()
  })
}

function addTask(data) {
  const payload = {
    name: data.name,
    description: data.description,
    users: [data.users],
    step_id: data.step,
    address_id: route.params.objectId,
  }
  if (data.deadline) {
    payload.deadline = data.deadline
  }
  useApi.requestV2('post', 'v1/tasks', payload).then(() => {
    showAddTaskForm.value = false
    loadTasks()
  })
}

function onImport() {
  useImport.confirmImportXML().then((result) => {
    Object.assign(useSurvey.addForm.data.value, result)
    useSurvey.addForm.update_errors()
  })
}

function syncWoningtool(file) {
  if (file instanceof File) {
    const reader = new FileReader()
    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target.result)
        Object.assign(useSurvey.addForm.data.value, jsonData)
      } catch (error) {
        console.error('Error parsing Woningtool file:', error)
      }
    }
    reader.onerror = (event) => console.error('File reading error:', event.target.error)
    reader.readAsText(file)
  } else {
    console.error('Invalid file object:', file)
  }
}
</script>
