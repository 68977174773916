import { createRouter, createWebHistory } from 'vue-router'
// import viewSurvey from '@/views/viewSurvey.vue'
import viewAddress from '@/views/viewAddress.vue'
import viewProjects from '@/views/viewProjects.vue'
import viewSurveyAdmin from '@/views/viewSurveyAdmin.vue'
import viewDetailSurvey from '@/views/viewDetailSurvey.vue'
import viewDetailAddress from '@/views/viewDetailAddress.vue'
import viewDetailProjects from '@/views/viewDetailProjects.vue'
import viewNotFound from '@/views/viewNotFound.vue'
import viewDetailOrganisation from '@/views/viewDetailOrganisation.vue'
import viewOrganisation from '@/views/viewOrganisation.vue'
import viewUserTasks from '@/views/viewUserTasks.vue'
import viewProfile from '@/views/viewProfile.vue'

import viewSurvey from '@/views/viewSurvey.vue'
import { authGuard } from '@auth0/auth0-vue'

const routes = [
  {
    path: '/',
    name: 'addresses',
    component: viewAddress,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Addresses',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/addresses/:objectId',
    name: 'address',
    component: viewDetailAddress,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Address',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: viewProjects,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Projects',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/projects/:objectId',
    name: 'project',
    component: viewDetailProjects,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Project',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/projects/:projectId/addresses/:objectId',
    name: 'project-address',
    component: viewDetailAddress,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Project',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: viewUserTasks,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Tasks',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: viewSurveyAdmin,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
  {
    path: '/surveys',
    name: 'surveys',
    component: viewSurvey,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
  {
    path: '/surveys/:objectId',
    name: 'survey',
    component: viewDetailSurvey,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: viewNotFound,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
  {
    path: '/organisations/:objectId',
    name: 'organisations',
    component: viewDetailOrganisation,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
  {
    path: '/organisation',
    name: 'organisation',
    component: viewOrganisation,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: viewProfile,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
