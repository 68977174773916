import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
// import formHandler from '@/use/formHandler'
import { ref, computed } from 'vue'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  // const organisationForm = formHandler()
  const address = ref({})
  const address_geometry = ref({})
  const label = ref({})

  const finishedSteps = computed(() => address.value.address_project[0].config.steps_completed)

  const currentStep = computed(() => {
    if (address.value.projects[0].config.stappen.length === finishedSteps.value.length) {
      return {}
    }
    let step =
      address.value.projects[0].config.stappen.find((step) => !finishedSteps.value.includes(step.step_id)) ||
      address.value.projects[0].config.stappen[0]
    return step
  })

  async function finishStep() {
    address.value.address_project[0].config.steps_completed.push(currentStep.value.step_id)

    let currentConfig = address.value.address_project[0].config || {}
    let config = { ...currentConfig, stappen: finishedSteps.value }

    let payload = {
      object_id: address.value.object_id,
      project_id: address.value.projects[0].object_id,
      config: config,
    }

    await useApi.request('post', 'addresses', 'update_address_project_config', payload)
  }

  async function unfinishStep() {
    address.value.address_project[0].config.steps_completed.pop(currentStep.value.step_id)

    let currentConfig = address.value.address_project[0].config || {}
    let config = { ...currentConfig, stappen: finishedSteps.value }

    let payload = {
      object_id: address.value.object_id,
      project_id: address.value.projects[0].object_id,
      config: config,
    }

    await useApi.request('post', 'addresses', 'update_address_project_config', payload)
  }

  const x = computed(() => {
    // 564112
    if ('centroide_rd' in address.value) {
      const coordinaat = parseCoordinatesRD(address.value.centroide_rd).lng
      return coordinaat
    }
    // if (address.value) {
    //   const coordinaat = parseCoordinates(address.value.centroide_rd).lng
    //   return coordinaat.substring(0, x.value.length - 3)
    // }
    return '0'
  })

  const y = computed(() => {
    if ('centroide_rd' in address.value) {
      const coordinaat = parseCoordinatesRD(address.value.centroide_rd).lat
      return coordinaat
    }
    // if (address.value) {
    //   const coordinaat = parseCoordinates(address.value.centroide_rd).lat
    //   return coordinaat.substring(0, x.value.length - 3)
    // }
    return '0'
  })

  const addressDetailFields = ref([
    { label: 'Address', key: 'address', type: 'string' },
    { label: 'Zip', key: 'zip', type: 'string' },
    { label: 'Place', key: 'place', type: 'string' },
    { label: 'Wijk', key: 'wijknaam', type: 'string' },
    { label: 'Buurt', key: 'buurtnaam', type: 'string' },
    { label: 'Waterschap', key: 'waterschapsnaam', type: 'string' },
    { label: 'BAG nummer-id', key: 'nummeraanduiding_id', type: 'string' },
    { label: 'BAG addresseerbaarobject-id', key: 'adresseerbaarobject_id', type: 'string' },
    {
      label: 'Omgevingsloket',
      key: 'action',
      component: 'listLink',
      componentProps: {
        lat: x, // Example latitude
        lng: y, // Example longitude
      },
    },
  ])

  async function getDetails(payload) {
    loadHandler.setLoadingState('get_address_details', true)
    try {
      const object_id = payload['object_id']
      const result = await useApi.requestV2('get', `v1/addresses/${object_id}`)

      const responseData = result.data
      address.value = { ...responseData, ...responseData.config }
      address_geometry.value = parseCoordinates(responseData.config.centroide_ll)

      const aoId = responseData.config['adresseerbaarobject_id']
      if (aoId) {
        await getLabel({ bagverblijfsobjectid: aoId })
      }
      return address.value
    } finally {
      loadHandler.setLoadingState('get_address_details', false)
    }
  }

  function parseCoordinates(coordinateString) {
    const [lng, lat] = coordinateString.replace('POINT(', '').replace(')', '').split(' ').map(parseFloat)
    return { lat, lng }
  }
  function parseCoordinatesRD(coordinateString) {
    const [lng, lat] = coordinateString.replace('POINT(', '').replace(')', '').split(' ')
    return { lat, lng }
  }

  async function getLabel(payload) {
    loadHandler.setLoadingState('get_label', true)
    try {
      const response = await useApi.requestV2('get', `v1/labels/${payload.bagverblijfsobjectid}`)
      // const response = await useApi.request('post', 'labels', 'get_by', payload)
      const response_data = response.data
      label.value = response_data
      loadHandler.setLoadingState('get_label', false)
    } finally {
      loadHandler.setLoadingState('get_label', false)
    }
  }

  return {
    loadHandler,
    getDetails,
    // updateOrganisation,
    addressDetailFields,
    address,
    address_geometry,
    label,
    x,
    finishedSteps,
    currentStep,
    finishStep,
    unfinishStep,
  }
}
